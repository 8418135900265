import React from 'react';
import AppRoutes from './router';
import './App.css';
// Prime React
import "primereact/resources/themes/lara-light-indigo/theme.css";   
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";  

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
