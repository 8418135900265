import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";

import gameStyles from "./edit-game.module.css";
import { createGame } from "../../redux/actions/game";
import { generatePlayableToken } from "../../redux/actions/game-url";
// import JSONEditor from "jsoneditor";
import JSONEditorComponent, { useJSONEditor } from "./jsoneditor";
import './edit-game.module.css';
import { mainApp } from "../../api/urls";


import OpenAI from "openai";
// import fs from "fs";

import { useEffect } from "react";

// Validation schema using Yup
const editGameSchema = Yup.object().shape({
  game_script: Yup.object().required("Game Script is required"),
});

const ViewEditGame = (props) => {
  
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { gameData } = props;

  const containerRef = useRef(null);
  const myRef = useRef({
    ...gameData.game_script
  });
  var jsonScript = JSON.stringify({
    ...gameData.game_script
  });
  const [updatedJSON, setUpdatedJSON] = useState({
    ...gameData.game_script
  });
  const handleJsonChange = (jsonString) => {
    jsonScript = jsonString;
  };
  // Initialize JSONEditor using the custom hook
  useJSONEditor(containerRef, { ...gameData.game_script }, handleJsonChange); // Initial JSON data

  // Load initial JSON data
  if (gameData.game_script) {
    // editor.set({...gameData.game_script});
  }

  let { id } = useParams();
  let toast;

  console.log("gameData", gameData)
  const [showDialog, setShowDialog] = useState();
  const [showAIDialog, setShowAIDialog] = useState();
  const [showAIDecision, setShowAIDecision] = useState(false);
  const [playableGameurl, SetPlayableGameUrl] = useState();
  const submitButtonRef = useRef(null);

  const [gameScriptValue, setGameScriptValue] = useState({
    ...gameData.game_script
  });

  const handleChange = (event) => {
    setGameScriptValue((prevValues) => ({
      ...prevValues,

      // event.JsObject has object value which are perfect for us to save
      ...event.jsObject,
    }));
  };
  const [showJsonEditor, setShowJsonEditor] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [aiScripValue, setAIScripValue] = useState('');
  const [loading, setLoading] = useState(false);

  const handleTextareaChange = (event) => {
    setAIScripValue(event.target.value);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleShowAI = () => {
    setShowAIDialog(true)
  };
  useEffect(() => {
    // Call createPlayableUrl when the component mounts
    createPlayableUrl();
  }, []); // Empty dependency array ensures this effect runs only once

  const handleUseAIDecision = () => {
    let text = aiScripValue
    console.log("text information", text)
    const jsonRegex = /\{(?:[^{}]|(?:\{(?:[^{}]|{\})*\}))*\}/;
    const match = text.match(jsonRegex);
    console.log("match", match)

    if (match) {
      const jsonString = match[0];
      console.log("jsonString", jsonString)
      try {
        const modifiedObject = JSON.parse(jsonString);
        console.log("jsonObject", modifiedObject);

        //TODO :- Need to update the this json object in the main script 
        var gameFile = gameData.game_script;

        console.log("game file information", gameFile)
        const matchKeys = ['Player', 'Name'];
        const objectIndex = gameFile.ObjectList.findIndex(obj =>
          matchKeys.every(key => obj[key] === modifiedObject[key])
        );

        if (objectIndex !== -1) {
          gameFile.ObjectList[objectIndex] = modifiedObject;
          submitButtonRef.current?.click();
        }


        console.log("This is the modified script", gameFile)


      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("No JSON found in the text.");
    }

    setShowAIDialog(false)
  };

  const handleAskAI = async () => {
    console.log('ASK AI Button Clicks');
    console.log(inputValue);
    setLoading(true);
    const openai = new OpenAI({ apiKey: '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', dangerouslyAllowBrowser: true });

    console.log("Game Script", gameData.game_script);
    var gameFile = JSON.stringify(gameData.game_script);

    console.log(gameFile);

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          { "role": "system", "content": "You are a helpful assistant." },
          { "role": "user", "content": "Convert this JSON " + inputValue + " " + gameFile + " , include entire object json, return only the object that needs to be modified." }
        ],
        model: "gpt-3.5-turbo-1106",
      });

      console.log("completion.choices ", completion.choices[0].message.content);
      setAIScripValue(completion.choices[0].message.content);
    } catch (error) {
      console.error("Error:", error);
    }
    finally {
      setLoading(false);
      setShowAIDecision(true)
    }
  }


  const handleShowHideJsonEditor = () => {
    console.log('show hide json editor bugtton clicked');
    setShowJsonEditor(!showJsonEditor);
  }
  const handleSubmit = (values, actions) => {

    console.log("Actions", actions)
    //  console.log('my ref: ',myRef);
    //   console.log('myRef.current.refContent.innerText: ',myRef.current.refContent.innerText);
    // console.log('my ref JSON.parse: ',JSON.parse(myRef.current.refContent.innerText));
    // console.log('gameScriptValue: ',gameScriptValue);
    // console.log('containerRef: ',containerRef);
    console.log('jsonScript: ', jsonScript);
    // return;
    dispatch(
      // createGame({ game_script: gameScriptValue }, id, (result) => {
      // createGame({ game_script:JSON.parse(myRef.current._reactInternals.ref.current.state.json) }, id, (result) => {
      createGame({ game_script: JSON.parse(jsonScript) }, id, (result) => {
        console.log(result);
        if (result.success) {
          // message
          toast.show({
            severity: "success",
            summary: "Success",
            detail: "Game edited successfully!",
            life: 3000,
          });
          actions.setSubmitting(false);

        } else {
          toast.show({
            severity: "error",
            summary: "Error",
            detail: "Error while editing the game. Please try again later.",
            life: 3000,
          });

          actions.setSubmitting(false);
        }
      })
    );
  };
  const sharableLinkClick = (e) => {
    e.preventDefault();
    const payload = {
      url_type: "PLAY_GAME",
      shared_with_all_users: true
    }
    dispatch(generatePlayableToken(payload, id, (result) => {
      if (result.success) {
        // message
        console.log(result);
        // const baseUrl = window.location.protocol + "//" + window.location.host;
        // const baseUrl = baseURL; // Accessing baseURL here

        SetPlayableGameUrl(mainApp + "/game-room/" + result.data.url_code);;
        setShowDialog(true);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: result.message,
          life: 3000
        })
      }
    }));
  };

  const playClick = () => {
    console.log("This is a play click")
    console.log("playableGameurl", playableGameurl)
    if (!playableGameurl) {
      createPlayableUrl()
    }

    window.open(playableGameurl, "_blank");
  }

  const shareClick = () => {
    console.log("This is a share click")
    console.log("playableGameurl", playableGameurl)
    if (!playableGameurl) {
      createPlayableUrl()
    }

    toast.show({
      severity: "success",
      summary: "Copy Game URL",
      detail: "Game URL copied!",
      life: 3000,
    });
  }

  const createPlayableUrl = () => {
    console.log("Called createPlayableUrl....")
    const payload = {
      url_type: "PLAY_GAME",
      shared_with_all_users: true
    }
    dispatch(generatePlayableToken(payload, id, (result) => {
      if (result.success) {
        // console.log(result);
        // const baseUrl = window.location.protocol + "//" + window.location.host;
        const useURL = mainApp + "/game-room/" + result.data.url_code;
        // console.log("baseUrl is:",useURL)
        if (window.isSecureContext && navigator.clipboard) {
          navigator.clipboard.writeText(useURL);
        } else {
          // Non SSL & Unsecure copy to clipboard
          const textArea = document.createElement("textarea");
          textArea.value = useURL;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            document.execCommand('copy');
          } catch (err) {
            console.error('Unable to copy to clipboard', err);
          }
          document.body.removeChild(textArea);
        }
        // navigator.clipboard.writeText(useURL);
        SetPlayableGameUrl(useURL);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: result.message,
          life: 3000
        })
      }
    }));
  }

  const onModelHideClick = () => {
    setShowDialog(false);
    setShowAIDialog(false);
    SetPlayableGameUrl("");
  };

  const openShareableLinkClick = (e) => {
    e.preventDefault();
    //navigate(playableGameurl, {});
    window.open(playableGameurl, "_blank");
  }

  const copyShareableLinkClick = (e) => {
    console.log("Copy Game URL", playableGameurl);
    navigator.clipboard.writeText(playableGameurl);
    toast.show({
      severity: "success",
      summary: "Copy Game URL",
      detail: "Game URL copied!",
      life: 3000,
    });
  }

  return (
    
    <div className="container-fluid">
      <Toast ref={(el) => (toast = el)} />
      <Formik
        enableReintialize
        initialValues={gameScriptValue}
        addGameSchema={editGameSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, isSubmitting, values }) => (
          <Form>
            <div className={`row my-5 ${gameStyles.edit_game_container}`}>
              <div className={` mb-3  ${gameStyles.card_heading_btn}`}>
                <div className={`${gameStyles.edit_game_title}`}>
                  {/* <p>{gameData.description}</p> */}
                  <p>(Note, this part on the left is for advanced users, if you just want to play, hit Share and Play buttons.)</p>
                  {/* <h2 className={` p-3 mb-0 ${gameStyles.edit_game_Chess_Pro_heading}`}>
                    {gameData.name}
                  </h2> */}
                </div>
                <div className={`${gameStyles.edit_gams_action_btn}`}>

                  <div className="card flex justify-content-center">
                    <Dialog
                      showHeader={false}
                      visible={showDialog}
                      style={{ width: "600px" }}
                      onHide={onModelHideClick}
                      className='dialog-box-container'
                    >
                      <div className={`p-3 ${gameStyles.dialog_heading}`}>
                        <div className={`${gameStyles.dialog_heading_icon}`}>
                          <div className={`me-2 ${gameStyles.square_line_icon}`}>
                            <img src="../images/square-share-line-svg.svg" alt="" />
                          </div>
                          <div>
                            <h1>
                              Get a shareable link for your game and share it
                              with your favourite groups.
                            </h1>
                          </div>
                        </div>
                        <div onClick={() => onModelHideClick()} className={`${gameStyles.cross_icon_img}`}>
                          <img src="../images/cross-icon.svg" alt="" />
                        </div>
                      </div>
                      <div className={`p-4 ${gameStyles.dialog_box_bottom}`}>
                        <div>
                          <h4>Copy this link below and share</h4>
                        </div>
                        <div className={`${gameStyles.dialog_text_edit_icons}`}>
                          <div className={`${gameStyles.dialog_link}`}>
                            <input type="text" value={`${playableGameurl}`} readOnly />
                          </div>
                          <div className={`${gameStyles.copy_open_link_icons}`}>
                            <span>
                              <Link onClick={copyShareableLinkClick}>
                                <img src="../images/copy-svg.svg" alt="" />
                              </Link>
                            </span>
                            <span className="ms-2">
                              <Link onClick={openShareableLinkClick}>
                                <img src="../images/open-link-svg.svg" alt="" />
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Dialog>

                    <Dialog
                      showHeader={false}
                      visible={showAIDialog}
                      onHide={onModelHideClick}

                    >
                      <div className={`p-3 ${gameStyles.dialog_heading}`}>
                        <div className={`${gameStyles.dialog_heading_icon}`}>
                          <div className={`me-2 ${gameStyles.square_line_icon}`}>
                            <img src="../images/square-share-line-svg.svg" alt="" />
                          </div>
                          <div>
                            <h1>
                              AI modified script
                            </h1>
                          </div>
                        </div>
                        <div onClick={() => onModelHideClick()} className={`${gameStyles.cross_icon_img}`}>
                          <img src="../images/cross-icon.svg" alt="" />
                        </div>
                      </div>
                      <div className={`p-4 ${gameStyles.dialog_box_bottom}`}>
                        <textarea cols={70} rows={15} value={aiScripValue} onChange={handleTextareaChange} />
                      </div>
                      {/* <div className={`p-4 ${gameStyles.dialog_box_bottom}`}>
                        <div className={`${gameStyles.edit_game_update_script} me-3`}>
                        </div>
                      </div> */}
                    </Dialog>


                  </div>
                </div>
              </div>

              {/* <div className="col-lg-9 col-md-8">
                <div className={`h-100 ${gameStyles.edit_game_card}`}>
                  <div style={{ maxWidth: "1400px", maxHeight: "100%" }}>
                    <JSONInput
                      placeholder={gameScriptValue} // data to display
                      name="game_script"
                      id="game_script"
                      theme="light_mitsuketa_tribute"
                      locale={locale}
                      ref={myRef}
                      colors={{
                        string: "#DAA520", // overrides theme colors with whatever color value you want
                      }}
                      height="650px"
                      width="100%"
                    />
                  </div>
                </div>
              </div> */}
              {loading && <div className={`${gameStyles.loader_overlay}`}>
                <div className={`${gameStyles.loader_spinner}`}>
                  Crafting your script with AI magic., please wait...
                </div>
              </div>
              }
              <div className="col-lg-9 col-md-8">
                <div className={`${gameStyles.edit_game_input_search} mb-3`}>
                  <div className={`${gameStyles.edit_game_textbox} me-3`}>
                    {/* <input type="text" placeholder="Ask AI" /> */}
                    <input type="text" placeholder="Ask AI" value={inputValue} onChange={handleInputChange} />
                  </div>
                  <div className={`${gameStyles.edit_game_search_save_btn}`}>
                    <div className={`${gameStyles.edit_game_search_btn} me-3`}>
                      <button onClick={handleAskAI} type="button">
                        Ask AI
                      </button>
                    </div>
                    {showAIDecision && (
                      <div className="d-flex align-items-center">
                        <div className={`${gameStyles.edit_game_search_btn} me-3`}>
                          <button type="button" onClick={handleUseAIDecision} >
                            Use AI Decision
                          </button>
                        </div>

                        <div className={`${gameStyles.edit_game_search_btn} me-3`}>
                          <button onClick={handleShowAI} type="button">
                            Show AI decision
                          </button>
                        </div>
                      </div>
                    )}
                    <div>
                      <button onClick={handleShowHideJsonEditor} type="button" className={`${gameStyles.edit_games_save_btn}`}>{showJsonEditor ? 'Hide JSON Editor' : 'Show JSON Editor'}</button>
                    </div>
                  </div>
                </div>

                {/* {showJsonEditor &&  */}
                <div className={`${gameStyles.edit_game_card}`}>
                  <div style={{ maxWidth: "1400px", maxHeight: "100%", display: showJsonEditor ? 'block' : 'none' }}  >
                    <div ref={containerRef} className={`${gameStyles.jsoneditor}`}></div>
                    {/* <JSONEditorComponent initialJSON={initialJSON} onJsonChange={handleJsonChange} /> */}
                    {/* <JSONEditorComponent initialJSON={gameScriptValue} onJsonChange={handleJsonChange} /> */}
                    {/* <pre>{JSON.stringify(gameScriptValue, null, 2)}</pre>
                  <pre>{gameScriptValue}</pre> */}
                  </div>
                </div>
                {/* } */}
              </div>
              <div className="col-lg-3 col-md-4 mt-md-0 mt-4">
                <div className={`h-100 ${gameStyles.edit_game_card}`}>
                  <h1 className={` p-3 ${gameStyles.edit_game_Chess_Pro_heading}`}>{gameData.name}</h1>
                  <div className={`p-3 ${gameStyles.edit_game_bottom}`}>
                    <div className={`${gameStyles.edit_cross_img_text}`}>
                      <div className={`${gameStyles.edit_cross_pro_img}`}>
                        <img src="../images/chess-pro-img.svg" alt="" />
                      </div>
                      <div className="ps-3">
                        <p>
                          Make sure you Save first, and then hit the Share button to copy the link and share it to other players!
                        </p>
                      </div>
                    </div>
                    <div className={`mt-3 ${gameStyles.cross_pro_btns}`}>
                      <span className="me-1 w-50">
                        <button
                          type="submit"
                          ref={submitButtonRef}
                          className={`${gameStyles.edit_games_save_btn}`}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Saving..." : "Save"}
                          <span className={`${gameStyles.btn_icon}`}>
                            <img src="../images/save-edit-icon.svg" alt="" />
                          </span>
                        </button>
                      </span>

                    </div>
                    <div>
                      <div className={`mt-4 ${gameStyles.cross_pro_img}`}>
                        <img src={gameData.original_image_url} alt="" />
                      </div>
                      <div className={` mt-3 ${gameStyles.play_btn}`}>
                        <span className="ms-1 w-100">
                          <Link
                            icon="pi pi-external-link"
                            onClick={shareClick}
                            // onClick={sharableLinkClick}
                            className={`${gameStyles.edit_games_share_btn}`}>
                            <span>
                              Share
                            </span>
                            <span className={` ${gameStyles.btn_icon}`}>
                              <img src="../images/share-icon.svg" alt="" />
                            </span>
                          </Link>
                        </span>
                        <span className="ms-1 w-100">
                          <Link
                            onClick={playClick}
                            // onClick={sharableLinkClick}
                            // to={`/preview-game/${gameData.id}`}
                            className={`${gameStyles.edit_games_play_btn}`}
                          >
                            <span>
                              Play
                            </span>
                            <span className="ps-3">
                              <i className="bi bi-play-circle"></i>
                            </span>
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
};

export default ViewEditGame;
