export const currentBrowserHostname = window.location.protocol + "//" + window.location.hostname;

// Determine if proxy is enabled or disabled
const isProxyEnabled = process.env.REACT_APP_PROXY === 'enabled';

// Define URLs
export const mainApp = currentBrowserHostname + (isProxyEnabled ? "" : ":8080");
export const signUpURL = currentBrowserHostname + (isProxyEnabled ? "/auth/sign-up" : ":7001/auth/sign-up");
export const loginURL = currentBrowserHostname + (isProxyEnabled ? "/auth/sign-in" : ":7002/auth/sign-in");
export const refreshTokenUrl = currentBrowserHostname + (isProxyEnabled ? "/auth/refresh-token" : ":7003/auth/refresh-token");
export const addGameURL = currentBrowserHostname + (isProxyEnabled ? "/create-my-game" : ":7004/create-my-game");
export const editGameURL = currentBrowserHostname + (isProxyEnabled ? "/edit-my-game" : ":7005/edit-my-game");
export const getGameURL = currentBrowserHostname + (isProxyEnabled ? "/list-my-games" : ":7006/list-my-games");
export const deleteGameURL = currentBrowserHostname + (isProxyEnabled ? "/delete-my-game" : ":7007/delete-my-game");
export const gameURL = currentBrowserHostname + (isProxyEnabled ? "/game-url/" : ":7009/game-url/");
export const gameStateURL = currentBrowserHostname + (isProxyEnabled ? "/games/play/" : ":7010/games/play/");
export const gameLoopURL = currentBrowserHostname + (isProxyEnabled ? "/games/play/" : ":7011/games/play/");
export const gameLoopSocketUrl = currentBrowserHostname + (isProxyEnabled ? "/" : ":7011/");

